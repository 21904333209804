import { List } from 'antd'
import { graphql, navigate } from 'gatsby'
import React from 'react'
import ProductItem from '../components/BlockSale/ProductItem'
import Layout from '../components/Layout'
import MenuCategory from '../components/MenuCategory'
import SEO from '../components/SEO'
import { localizedSlug } from '../gatsby/NodeHelpers'
import i18n from './i18n'

const ShopPage = ({ location, data: { productList }, pageContext: { locale, total, currentPage, limit } }: any) => {
  const renderItem = (product: any, index: number) => (
    <List.Item style={{ height: '100%' }}>
      <ProductItem showTag={true} key={product.node.uid} product={product} locale={locale} />
    </List.Item>
  )

  return (
    <Layout locale={locale} location={location}>
      <SEO location={location} locale={locale} title={i18n[locale].prodCate} />
      <MenuCategory locale={locale} type="shop" title="Shop" />
      <div className="sg-list-items">
        <List
          grid={{ gutter: [8, 20] as any, xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 4 }}
          itemLayout="horizontal"
          size="default"
          pagination={{
            onChange: page => {
              const uriPage = page === 1 ? '' : page
              navigate(localizedSlug({ lang: locale, uid: `shop/${uriPage}` }))
            },
            pageSize: limit,
            current: currentPage,
            total,
          }}
          dataSource={productList.edges}
          renderItem={renderItem}
        />
      </div>
    </Layout>
  )
}

export default ShopPage

export const pageQuery = graphql`
  query ProductListBySlug($locale: String!, $skip: Int!, $limit: Int!) {
    productList: allPrismicShop(
      limit: $limit
      skip: $skip
      filter: { lang: { eq: $locale } }
      sort: { fields: [data___product_code, last_publication_date], order: DESC }
    ) {
      edges {
        node {
          ...IndexSaleBlockFragment
        }
      }
    }
  }
`
